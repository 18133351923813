import React from 'react';
import classnames from 'classnames';
import {
  bool,
  func,
  node,
  number,
  string
} from 'prop-types';
import { NotificationBadge, SkeletonBlock, CardMedia } from '@one-thd/sui-atomic-components';

import { sanitizeImageUrl } from '../../utils/promo-utils';
import { ProductImageLockBadge } from './subcomponents/ProductImageLockBadge';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';

export const ProductImage = ({
  alt,
  children,
  id,
  loading,
  onClick,
  isLocked,
  preview,
  quantity,
  size,
  src,
  inDrawer
}) => {
  const { type } = usePromoPresentation();
  const sanitizedUrl = src ? sanitizeImageUrl(src).replace(/(400|1000)(?=(.(jpg|webp)))/, '145') : null;
  const isButton = onClick && sanitizedUrl;
  const wrapperClasses = classnames(
    'sui-flex sui-flex-col',
    {
      'sui-gap-1': type === 'pod'
    }
  );
  const buttonClasses = classnames(
    'sui-w-full focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-warning',
  );

  // eslint-disable-next-line tailwindcss/no-arbitrary-value
  const imageClasses = classnames({
    'sui-w-full': type === 'pod',
    'sui-w-[5.0rem] sui-h-[5.0rem]': type === 'card' && inDrawer,
    // eslint-disable-next-line max-len
    'sui-w-[33%] sui-min-w-[8.7rem] sui-min-h-[8.7rem] sui-max-w-[13.1rem] sui-max-h-[13.1rem]': type === 'card' && !inDrawer,
    'sui-opacity50': preview
  });

  const contentsJSX = (
    <>
      {isLocked && <ProductImageLockBadge />}
      {loading
        ? <SkeletonBlock disablePadding height={size} width={size} />
        : (
          <CardMedia
            alt={alt}
            className={imageClasses}
            id={id}
            src={sanitizedUrl}
            style={{ width: { size }, height: { size } }}
          />
        )}
    </>
  );

  if (type === 'card' && !inDrawer) {
    return <>{contentsJSX}</>;
  }

  return (
    <div className={wrapperClasses}>
      <NotificationBadge
        badgeContent={quantity}
        color="primary"
        invisible={!quantity}
        variant="strong"
      >
        <div className="sui-mx-1" style={{ width: size }}>
          {isButton ? (
            <button
              className={buttonClasses}
              onClick={onClick}
              style={{ height: size }}
              type="button"
            >
              {contentsJSX}
            </button>
          ) : (
            <div className="sui-w-full">
              {contentsJSX}
            </div>
          )}
        </div>
      </NotificationBadge>
      {children}
    </div>
  );
};

ProductImage.displayName = 'ProductImage';

ProductImage.propTypes = {
  alt: string,
  children: node,
  id: string,
  loading: bool,
  isLocked: bool,
  preview: bool,
  onClick: func,
  quantity: number,
  size: string.isRequired,
  src: string,
  inDrawer: bool,
};

ProductImage.defaultProps = {
  alt: '',
  children: null,
  id: null,
  loading: false,
  isLocked: false,
  preview: false,
  quantity: null,
  onClick: null,
  src: null,
  inDrawer: false
};
