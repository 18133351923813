import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { IconButton } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';

import {
  PodSpacer,
  ProductImage,
  ProductPod,
  ProductRating,
  ProductATC
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

const HeaderPriceRating = ({
  itemId,
  storeId,
  titleMaxLine,
  showExactPriceOnLoad,
  hidePriceBadge,
  showDisabledATC,
  onSavingsCenterToggle,
  enableATC,
  silentATC,
  disabled,
  sharedSection,
  onAction,
  hideBulkPrice,
  hideLimitPerOrder,
  showPreferredPricingBadge,
  showPreferredPricingBadgeToolTip,
  hideRebates,
  hideSavingsText
}) => {
  return (
    <>
      <PodSpacer minHeight="32px">
        <ProductHeader
          brand="above"
          itemId={itemId}
          titleMaxLine={titleMaxLine}
          disableShopThisCollection
          onAction={onAction}
        />
      </PodSpacer>
      <PodSpacer minHeight="25px" padding={['BOTTOM']}>
        <ProductRating
          itemId={itemId}
        />
      </PodSpacer>
      <PodSpacer minHeight="45px" padding={['TOP']}>
        <Price
          itemId={itemId}
          large={false}
          type="simple"
          hidePromotions={false}
          storeId={storeId}
          onSavingsCenterToggle={onSavingsCenterToggle}
          disableRangePricing={showExactPriceOnLoad}
          hideBadge={hidePriceBadge}
          showPreferredPricingBadge={showPreferredPricingBadge}
          showPreferredPricingBadgeToolTip={showPreferredPricingBadgeToolTip}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
        />
      </PodSpacer>

      {enableATC && (
        <ProductATC
          showDisabledATC={showDisabledATC}
          disabled={disabled}
          silent={silentATC}
          sharedSection={sharedSection}
          outline
        />
      )}

    </>
  );
};

const KPFAndFulfillment = ({
  itemId,
  storeId,
  hideFulfillment,
  minKpfs,
  showKPF
}) => {
  return (
    <>
      <PodSpacer padding={['TOP']}>
        {showKPF
          && (
            <KeyProductFeatures
              hideImage
              itemId={itemId}
              minfeatures={minKpfs}
              maxfeatures={5}
              simple
              oneColumnGrid
            />
          )}
      </PodSpacer>
      {!hideFulfillment && (
        <PodSpacer minHeight="80px">
          <FulfillmentPodStore itemId={itemId} storeId={storeId} hideForChooseYourOptions />
          <FulfillmentPodShipping itemId={itemId} storeId={storeId} />
        </PodSpacer>
      )}
    </>
  );
};

const Caret = ({ useCaret, onArrowClick, isHDQCSku }) => {
  return useCaret && (
    <div className="product-pod-mini__caret">
      {!isHDQCSku && (
        <IconButton
          onClick={onArrowClick}
          color="brand"
          icon={ArrowForward}
          aria-label="open menu"
        />
      )}
    </div>
  );
};

export const getExperienceName = () => {
  if (!window?.experienceMetadata?.name) {
    return '';
  }

  return window?.experienceMetadata?.name;
};

export const PromotionDrawerProductPod = ({
  itemId,
  storeId,
  position,
  onArrowClick,
  callToActionSection,
  showKPF,
  showDisabledATC,
  hidePriceBadge,
  disabled,
  enableATC,
  inCartQuantity,
  onAction,
  silentATC,
  hideCaret,
  sharedSection,
  showExactPriceOnLoad,
  titleMaxLine = 2,
  hideFulfillment,
  isHDQCSku,
  hideBulkPrice,
  hideLimitPerOrder,
  showPreferredPricingBadge,
  showPreferredPricingBadgeToolTip,
  hideRebates,
  hideSavingsText,
  experienceUsesProductImageCarousel
}) => {
  const minKpfs = 3;

  const { channel } = useContext(ExperienceContext);

  const isMobileChannel = channel === 'mobile';
  const useCaret = hideFulfillment && !hideCaret;
  const shouldRenderCallToAction = !enableATC && callToActionSection !== null;

  const currentExperience = getExperienceName();
  const useProductImageCarousel = experienceUsesProductImageCarousel[currentExperience] && isMobileChannel;
  const [useDualImageCarousel, setProductImageCarousel] = useState(useProductImageCarousel);

  return (
    <div data-testid={`promotion-products-product-pod-${itemId}`}>
      <ProductPod
        itemId={itemId}
        storeId={storeId}
        analyticsData={{ parent: 'promotion-drawer', position }}
        render={(pod) => (
          <>
            <div className="sui-grid sui-grid-cols-12 sui-gap-2">
              <div className="sui-col-span-3 sui-border-primary sui-p-2">
                <ProductImage
                  itemId={pod.itemId}
                  showSecondaryImage={useDualImageCarousel}
                  dualImageSwipe={useDualImageCarousel}
                  inCartQuantity={inCartQuantity}
                />
              </div>
              <div className="sui-col-span-8 sui-border-primary sui-p-2" style={{ gridColumn: 'span 8 / span 8' }}>
                <HeaderPriceRating
                  itemId={pod.itemId}
                  storeId={pod.storeId}
                  titleMaxLine={titleMaxLine}
                  showExactPriceOnLoad={showExactPriceOnLoad}
                  hidePriceBadge={hidePriceBadge}
                  showDisabledATC={showDisabledATC}
                  onSavingsCenterToggle={pod.onSavingsCenterToggle}
                  disabled={disabled}
                  enableATC={enableATC}
                  silentATC={silentATC}
                  sharedSection={sharedSection}
                  onAction={onAction}
                  hideBulkPrice={hideBulkPrice}
                  hideLimitPerOrder={hideLimitPerOrder}
                  showPreferredPricingBadge={showPreferredPricingBadge}
                  showPreferredPricingBadgeToolTip={showPreferredPricingBadgeToolTip}
                  hideRebates={hideRebates}
                  hideSavingsText={hideSavingsText}
                />
                <KPFAndFulfillment
                  itemId={pod.itemId}
                  storeId={pod.storeId}
                  hideFulfillment={hideFulfillment}
                  minKpfs={minKpfs}
                  showKPF={showKPF}
                />
              </div>
              <div className="sui-col-span-1 sui-border-1 sui-p-2">
                <Caret
                  useCaret={useCaret}
                  onArrowClick={onArrowClick}
                  isHDQCSku={isHDQCSku}
                />
              </div>
            </div>
            {shouldRenderCallToAction && (
              <PodSpacer minHeight="45px">
                {callToActionSection}
              </PodSpacer>
            )}
          </>
        )}
      />
    </div>
  );
};

PromotionDrawerProductPod.displayName = 'PromotionDrawerProductPod';

PromotionDrawerProductPod.dataModel = extend(
  Price,
  ProductHeader,
  ProductImage,
  ProductPod,
  ProductRating,
  ProductATC,
  KeyProductFeatures,
  FulfillmentPodShipping,
  FulfillmentPodStore
);

PromotionDrawerProductPod.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  onArrowClick: PropTypes.func.isRequired,
  callToActionSection: PropTypes.element.isRequired,
  showKPF: PropTypes.bool.isRequired,
  showDisabledATC: PropTypes.bool.isRequired,
  hidePriceBadge: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  enableATC: PropTypes.bool.isRequired,
  inCartQuantity: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
  silentATC: PropTypes.bool.isRequired,
  hideCaret: PropTypes.bool.isRequired,
  sharedSection: PropTypes.string.isRequired,
  showExactPriceOnLoad: PropTypes.bool.isRequired,
  titleMaxLine: PropTypes.number.isRequired,
  hideFulfillment: PropTypes.bool.isRequired,
  isHDQCSku: PropTypes.bool.isRequired,
  hideBulkPrice: PropTypes.bool.isRequired,
  hideLimitPerOrder: PropTypes.bool.isRequired,
  showPreferredPricingBadge: PropTypes.bool.isRequired,
  showPreferredPricingBadgeToolTip: PropTypes.bool.isRequired,
  hideRebates: PropTypes.bool.isRequired,
  hideSavingsText: PropTypes.bool.isRequired,
  experienceUsesProductImageCarousel: PropTypes.object.isRequired
};