import { useEffect, useMemo, useState } from 'react';

import { getItemIdList, noop } from '../utils/promo-utils';
import { usePromotionProductsPage } from './usePromotionProductsPage';
import { useSearchProducts } from './useSearchProducts';
import { useNewRelicLogging } from './useNewRelicLogging';

export const useRewardDrawer = ({
  promotion,
  refinedNvalue,
  itemGroup,
  skip: skipSearchProducts,
  rewardDrawerMaxLength
}) => {
  // * initial flow *
  //
  // (new promotion)
  // (new drawer itemIds)
  //    -> searchModel -> products data
  // (products data (length))
  //    -> updated start index
  // (updated start index)
  //    -> promotionProductsPage -> new placeholder itemIds

  // * user action triggered flow *
  //
  // (new placeholder itemIds, user action)
  //    -> searchModel -> products data
  // (products data (length))
  //    -> updated start index
  // (updated start index)
  //    -> promotionProductsPage -> new drawer itemIds

  const eligibilityCriterion = promotion?.eligibilityCriteria?.find(
    ({ itemGroup: criterionItemGroup }) => itemGroup === criterionItemGroup
  );
  const totalProducts = eligibilityCriterion?.searchReport?.totalProducts || 0;
  const disablePagination = Boolean(refinedNvalue);
  const { sendDataToNewRelic } = useNewRelicLogging();
  const [itemIdsForSearchRequest, setItemIdsForSearchRequest] = useState([]);
  const [products, setProducts] = useState([]);
  const [itemIdsForPlaceholders, setItemIdsForPlaceholders] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [observer, setObserver] = useState(null);

  // new promotion triggers full reset/initialization
  useEffect(() => {
    const initialItemIds = getItemIdList(promotion, itemGroup);
    setItemIdsForSearchRequest(initialItemIds);
    setStartIndex(0);
    setProducts([]);
    setItemIdsForPlaceholders(initialItemIds); // gives something in drawer while search initializes

    // Trigger Initial Load Event
    try {
      const props = {
        actionName: 'PromoExpansionPaginationInitialLoad',
        experienceTag: promotion?.experienceTag,
        subExperience: promotion?.subExperienceTag,
        anchorItemId: promotion?.anchorItemId,
        promotionId: promotion?.promotionId,
        totalProducts,
        itemGroup,
      };
      sendDataToNewRelic(props);
    } catch (error) {
      console.error('Error sending data to New Relic:', error);
    }
  }, [itemGroup, promotion, totalProducts]);

  // new itemIdsForSearchRequest triggers a request for new products
  const {
    products: newProducts,
    loading: isLoadingProducts,
    error: searchError,
  } = useSearchProducts(
    itemIdsForSearchRequest,
    refinedNvalue,
    skipSearchProducts
  );

  // new products triggers an update to products and startIndex
  useEffect(() => {
    const lastExistingItemId = products.slice(-1)[0]?.itemId;
    const lastNewItemId = newProducts.slice(-1)[0]?.itemId;
    const hasNewProducts = !!lastNewItemId && lastNewItemId !== lastExistingItemId;
    // We do this check to prevent a recursive useEffect loop
    if (hasNewProducts) {
      const newProductsForPromoPods = products.concat(newProducts);
      setProducts(newProductsForPromoPods);
      setStartIndex(newProductsForPromoPods.length);
      setItemIdsForPlaceholders([]);
      // Trigger Scroll and Load Event
      try {
        const ScrollLoadProps = {
          actionName: 'PromoExpansionPaginationScrollAndLoad',
          experienceTag: promotion?.experienceTag,
          subExperience: promotion?.subExperienceTag,
          anchorItemId: promotion?.anchorItemId,
          promotionId: promotion?.promotionId,
        };
        sendDataToNewRelic(ScrollLoadProps);

        // Trigger Total Item Load Event if the maximum number of items is loaded
        if (newProductsForPromoPods.length >= rewardDrawerMaxLength) {
          const maxItemsProps = {
            actionName: 'PromoExpansionPaginationMaxItemsLoaded',
            experienceTag: promotion?.experienceTag,
            subExperience: promotion?.subExperienceTag,
            anchorItemId: promotion?.anchorItemId,
            promotionId: promotion?.promotionId,
          };
          sendDataToNewRelic(maxItemsProps);
        }
      } catch (error) {
        console.error('Error sending data to New Relic:', error);
      }
    }
  }, [newProducts, products, promotion, totalProducts, rewardDrawerMaxLength]);

  // new startIndex triggers a request for new pageItemIds
  const {
    pageItemIds,
    loading: isLoadingPage
  } = usePromotionProductsPage(
    promotion,
    eligibilityCriterion,
    startIndex,
    disablePagination,
  );

  const loading = isLoadingProducts || isLoadingPage;

  // new pageItemIds updates placeholderItemIds
  useEffect(() => {
    const lastPlaceholderItemId = itemIdsForPlaceholders.slice(-1)[0];
    const lastNewPlaceholderItemId = pageItemIds?.slice(-1)[0];
    // We do this check to prevent a recursive useEffect loop
    const hasNewPlaceholderItemIds = lastNewPlaceholderItemId && lastNewPlaceholderItemId !== lastPlaceholderItemId;
    if (hasNewPlaceholderItemIds) {
      setItemIdsForPlaceholders(pageItemIds);
    }
  }, [itemIdsForPlaceholders, pageItemIds]);

  // new placeholder itemIds trigger new observer
  // observer is updated in useEffect so we can leverage the cleanup function to disconnect
  useEffect(() => {
    if (!itemIdsForPlaceholders.length) {
      setObserver(null);
      return noop;
    }

    const newObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setItemIdsForSearchRequest(itemIdsForPlaceholders);
      }
    });

    setObserver(newObserver);

    const cleanupFunction = () => {
      if (newObserver?.disconnect) {
        newObserver.disconnect();
      }
    };

    return cleanupFunction;
  }, [itemIdsForPlaceholders]);

  const rewardDrawerMemo = useMemo(() => {
    return {
      observer,
      loading,
      itemIdsForPlaceholders,
      products,
      totalProducts,
      searchError,
    };
  }, [
    observer,
    loading,
    itemIdsForPlaceholders,
    products,
    totalProducts,
    searchError,
  ]);

  return rewardDrawerMemo;
};
