import React, { createContext, useMemo } from 'react';
import { node } from 'prop-types';

import MESSAGES from '../config/messages';
import { usePromoCart } from '../hooks/usePromoCart';
import { usePromoModel } from '../hooks/usePromoModel';
import { usePromoPresentation } from '../hooks/usePromoPresentation';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  BMSM_SUBEXPERIENCE_TAGS,
  DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  MESSAGE_STATE,
  MSB_SUBEXPERIENCE_TAGS
} from '../utils/constants';
import { toWord } from '../utils/promo-utils';
import { formatPrice, formattedReward } from '../utils/promo-presentation-utils';

/*
 * MESSAGES is a map of strings that the messages within the drawer can pull from. Dynamic values can be inserted
 * by writing in the format `$varName`.
 *
 * Current list of known variables:
 *
 * - currentAmount
 * - currentMetAmount
 * - expDay
 * - expMonth
 * - maxRewardAmount
 * - qualifyingAmount
 * - remainingAmount
 * - rewardAmount
 * - src1ListCount
 * - tgt1ListCount
 * - totalRewardAmount
 *
 * Escape characters can also be used to apply certain styling:
 * - `\n` new line
 * - `\u00a0` non breaking space
 */

const DEFAULT_PROMO_MESSAGE_CONTEXT = Object.freeze({ messages: MESSAGES });

export const PromoMessageContext = createContext(DEFAULT_PROMO_MESSAGE_CONTEXT);

export const PromoMessageProvider = ({ children }) => {
  const {
    anchorItem,
    bogoMaxQuantity,
    displayableSecondListItemIds,
    expDate,
    isAnchorFulfillable,
    isAwaitingData,
    isBmsm,
    listHasFulfillment,
    refinedNvalue,
    rewardTiers,
    firstListTotalProducts,
    secondListTotalProducts,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    subExperience,
    displayableFirstListItemIds
  } = usePromoModel();
  const {
    currentPromoTier,
    previousPromoTier,
    firstListCartValue,
    isPromoAchieved,
    promoCartQuantity,
    promoCartValue,
    promoSuccessLevel,
    selectedItemsModel,
    bogoSelectedProduct
  } = usePromoCart();
  const { isRewardEnabled } = usePromoPresentation();

  const messageState = useMemo(() => {

    const bmsmPromoFulfillable = isBmsm && listHasFulfillment;

    switch (subExperience) {
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
      if (!!anchorItem?.fulfillment && !isAnchorFulfillable) {
        return MESSAGE_STATE.anchorItemUnfulfillable;
      }
      if (promoSuccessLevel === 'almost' || selectedItemsModel?.length || bogoSelectedProduct) {
        return MESSAGE_STATE.almost;
      }

      if (isPromoAchieved || isRewardEnabled || promoSuccessLevel === 'success') {
        return MESSAGE_STATE.success;
      }
      break;
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne:
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff:
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff:
      return isPromoAchieved ? MESSAGE_STATE.success : MESSAGE_STATE.initial;
    default: break;
    }

    if (!!anchorItem?.fulfillment && !isAnchorFulfillable && !bmsmPromoFulfillable) {
      return MESSAGE_STATE.anchorItemUnfulfillable;
    }

    if (!isAwaitingData && !listHasFulfillment) {
      return MESSAGE_STATE.src1ListUnfulfillable;
    }

    if (isPromoAchieved || promoSuccessLevel === 'success') {
      return MESSAGE_STATE.success;
    }

    if (isRewardEnabled || promoSuccessLevel === 'almost') {
      return MESSAGE_STATE.almost;
    }

    return MESSAGE_STATE.initial;
  }, [
    anchorItem,
    isAnchorFulfillable,
    isAwaitingData,
    isPromoAchieved,
    isRewardEnabled,
    listHasFulfillment,
    promoSuccessLevel,
    subExperience,
    selectedItemsModel,
    bogoSelectedProduct,
    isBmsm
  ]);

  // The names of the keys matter for this object. The message format function uses the key names
  // to determine where in the string it needs to replace. Be careful if you change any property names here.
  const messageReplaceValues = useMemo(() => {
    const expMonth = expDate?.month || null;
    const expDay = expDate?.day || null;
    let currentAmount = null;
    let maxRewardAmount = null;
    let qualifyingAmount = null;
    let remainingAmount = null;
    let rewardAmount = null;
    let currentMetAmount = null;
    let src1ListCount = null;
    let tgt1ListCount = null;
    let totalRewardAmount = null;

    const {
      maxAllowedRewardAmount,
      minPurchaseQuantity,
      minPurchaseAmount,
      rewardAmountPerOrder,
      rewardAmountPerItem,
      rewardFixedPrice,
      rewardPercent
    } = rewardTiers?.[currentPromoTier] || {};

    const promoTier = isPromoAchieved ? currentPromoTier : previousPromoTier;

    const {
      rewardAmountPerOrder: previousRAPO,
      rewardFixedPrice: previousRFP,
      rewardPercent: previousRP
    } = rewardTiers[promoTier];

    if (refinedNvalue) {
      src1ListCount = '1000+';
    } else {
      src1ListCount = `${displayableFirstListItemIds?.length}`;
    }

    if (displayableSecondListItemIds?.length) {
      tgt1ListCount = secondListTotalProducts;
    }

    const isCartEmpty = promoCartValue === 0;

    const displayAmount = () => {
      if (isCartEmpty) return '$0';
      if (promoCartValue >= minPurchaseAmount) return formatPrice(minPurchaseAmount, true);
      return formatPrice(promoCartValue, true);
    };

    switch (subExperience) {
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne: {
      maxRewardAmount = bogoMaxQuantity;
      break;
    }
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
      rewardAmount = `${rewardPercent}%`;
      maxRewardAmount = bogoMaxQuantity;
      break;
    }
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff: {
      rewardAmount = formattedReward({ rewardAmountPerOrder }, true);
      maxRewardAmount = bogoMaxQuantity;
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne: {
      currentAmount = formatPrice(firstListCartValue, true);
      qualifyingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount, true);
      remainingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount - firstListCartValue, true);
      maxRewardAmount = formatPrice(maxAllowedRewardAmount, true);
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff: {
      currentAmount = formatPrice(firstListCartValue, true);
      qualifyingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount, true);
      remainingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount - firstListCartValue, true);
      rewardAmount = `${rewardPercent}%`;
      maxRewardAmount = formatPrice(maxAllowedRewardAmount, true);
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff: {
      currentAmount = formatPrice(firstListCartValue, true);
      qualifyingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount, true);
      remainingAmount = formatPrice(src1EligibilityCriteria.minPurchaseAmount - firstListCartValue, true);
      rewardAmount = formattedReward({ rewardAmountPerOrder }, true);
      maxRewardAmount = formatPrice(maxAllowedRewardAmount, true);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB1: {
      currentAmount = promoCartQuantity;
      qualifyingAmount = minPurchaseQuantity;
      remainingAmount = Math.max(0, minPurchaseQuantity - promoCartQuantity);
      rewardAmount = `${rewardPercent}%`;
      currentMetAmount = `${rewardPercent}%`;
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB2: {
      currentAmount = promoCartQuantity;
      qualifyingAmount = minPurchaseQuantity;
      remainingAmount = Math.max(0, minPurchaseQuantity - promoCartQuantity);
      rewardAmount = formatPrice(rewardAmountPerOrder);
      currentMetAmount = formatPrice(rewardAmountPerOrder);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB3: {
      currentAmount = promoCartQuantity;
      qualifyingAmount = minPurchaseQuantity;
      remainingAmount = Math.max(0, minPurchaseQuantity - promoCartQuantity);
      rewardAmount = formatPrice(rewardAmountPerItem);
      totalRewardAmount = formatPrice(rewardAmountPerItem * promoCartQuantity);
      currentMetAmount = formatPrice(rewardAmountPerItem * promoCartQuantity);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB4: {
      currentAmount = displayAmount();
      qualifyingAmount = formatPrice(minPurchaseAmount);
      remainingAmount = formatPrice(Math.max(0, minPurchaseAmount - promoCartValue), true);
      rewardAmount = `${rewardPercent}%`;
      currentMetAmount = `${rewardPercent}%`;
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB5: {
      currentAmount = displayAmount();
      qualifyingAmount = formatPrice(minPurchaseAmount);
      remainingAmount = formatPrice(Math.max(0, minPurchaseAmount - promoCartValue), true);
      rewardAmount = formatPrice(rewardAmountPerOrder);
      currentMetAmount = formatPrice(rewardAmountPerOrder);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB6: {
      currentAmount = displayAmount();
      qualifyingAmount = formatPrice(minPurchaseAmount);
      remainingAmount = formatPrice(Math.max(0, minPurchaseAmount - promoCartValue), true);
      rewardAmount = formatPrice(rewardAmountPerItem);
      totalRewardAmount = formatPrice(rewardAmountPerItem * promoCartQuantity);
      currentMetAmount = formatPrice(rewardAmountPerItem * promoCartQuantity);
      break;
    }
    case BMSM_SUBEXPERIENCE_TAGS.BMSM1:
    case BMSM_SUBEXPERIENCE_TAGS.BMSM2:
    case BMSM_SUBEXPERIENCE_TAGS.BMSM7: {
      currentAmount = promoCartQuantity;
      remainingAmount = Math.max(0, minPurchaseQuantity - promoCartQuantity);
      rewardAmount = formattedReward({ rewardAmountPerOrder, rewardPercent, rewardFixedPrice }, true);
      currentMetAmount = formattedReward({
        rewardAmountPerOrder: previousRAPO,
        rewardPercent: previousRP,
        rewardFixedPrice: previousRFP
      }, true);
      break;
    }
    case BMSM_SUBEXPERIENCE_TAGS.BMSM4:
    case BMSM_SUBEXPERIENCE_TAGS.BMSM5: {
      currentAmount = formatPrice(promoCartValue, true);
      remainingAmount = formatPrice(Math.max(0, minPurchaseAmount - promoCartValue), true);
      rewardAmount = formattedReward({ rewardAmountPerOrder, rewardPercent, rewardFixedPrice }, true);
      currentMetAmount = formattedReward({
        rewardAmountPerOrder: previousRAPO,
        rewardPercent: previousRP,
        rewardFixedPrice: previousRFP
      }, true);
      break;
    }
    default: {
      break;
    }
    }

    return Object.freeze({
      currentAmount,
      currentMetAmount,
      expMonth,
      expDay,
      maxRewardAmount,
      qualifyingAmount,
      remainingAmount,
      rewardAmount,
      src1ListCount,
      tgt1ListCount,
      src1MinPurchaseQuantity: src1EligibilityCriteria.minPurchaseQuantity || null,
      src1MinPurchaseQuantityWord: toWord(src1EligibilityCriteria.minPurchaseQuantity) || null,
      tgt1MinPurchaseQuantity: tgt1EligibilityCriteria.minPurchaseQuantity || null,
      tgt1MinPurchaseQuantityWord: toWord(tgt1EligibilityCriteria.minPurchaseQuantity) || null,
      totalRewardAmount
    });
  }, [
    expDate?.month,
    expDate?.day,
    firstListCartValue,
    promoCartQuantity,
    promoCartValue,
    refinedNvalue,
    rewardTiers,
    currentPromoTier,
    isPromoAchieved,
    previousPromoTier,
    refinedNvalue,
    displayableFirstListItemIds?.length,
    displayableSecondListItemIds?.length,
    promoCartValue,
    subExperience,
    src1EligibilityCriteria.minPurchaseQuantity,
    src1EligibilityCriteria.minPurchaseAmount,
    tgt1EligibilityCriteria.minPurchaseQuantity,
    firstListTotalProducts,
    secondListTotalProducts,
    bogoMaxQuantity,
    promoCartQuantity,
  ]);

  const contextValue = useMemo(() => {
    return Object.freeze({
      messageReplaceValues,
      messages: MESSAGES,
      messageState,
    });
  }, [messageReplaceValues, messageState]);

  return (
    <PromoMessageContext.Provider value={contextValue}>
      {children}
    </PromoMessageContext.Provider>
  );
};

PromoMessageProvider.propTypes = {
  children: node.isRequired
};
